import { UrlParamsReplace, getUrlFromMultipleQueryParams } from './index';
const ApiUrl = process.env.NEXT_PUBLIC_API_URL;
export const CREATE_INVESTMENT_URL = () => UrlParamsReplace('/investments');

export const GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL = (
    entityId,
    opportunityId
) =>
    UrlParamsReplace(
        '/investments?entityId=:entityId&opportunityId=:opportunityId',
        { entityId, opportunityId }
    );

export const GET_OPPORTUNITY_INVESTMENTS_URL = (id, page, limit) =>
    UrlParamsReplace('/opportunities/:id/investments?page=:page&limit=:limit', {
        id,
        page,
        limit,
    });

export const GET_INVESTOR_INVESTMENTS_URL = (id, page, limit) =>
    UrlParamsReplace('/investors/:id/investments?page=:page&limit=:limit', {
        id,
        page,
        limit,
    });

export const GET_INVESTOR_INVESTMENTS_BY_STATUS_URL = (
    id,
    page,
    limit,
    status,
    column,
    sortOrder
) =>
    UrlParamsReplace(
        '/investors/:id/investments/status?page=:page&limit=:limit&status=:status&column=:column&sortOrder=:sortOrder',
        {
            id,
            page,
            limit,
            status,
            column,
            sortOrder,
        }
    );

export const GET_INVESTOR_INVESTMENTS_STATS = (investorId) =>
    UrlParamsReplace('/v2/investors/:investorId/investments/stats', { investorId });

export const MARK_USER_CONSENT_URL = () => UrlParamsReplace('/audit-consent');

export const GET_USER_CONSENT_URL = (entityId, type) =>
    UrlParamsReplace('/audit-consent?entityId=:entityId&type=:type', {
        entityId,
        type,
    });

export const CREATE_OFFLINE_INVESTMENT_URL = () =>
    UrlParamsReplace('/investments/offline');

export const GET_TRANCHE_PENDING_INVESTMENTS = (
    opportunityId,
    trancheStatus,
    startInvestmentDate,
    endInvestmentDate,
    paymentMode
) =>
    UrlParamsReplace(
        `/investments/tranche?opportunityId=:opportunityId&trancheStatus=:trancheStatus&paymentMode=:paymentMode&startInvestmentDate=:startInvestmentDate&endInvestmentDate=:endInvestmentDate`,
        {
            opportunityId,
            trancheStatus,
            startInvestmentDate,
            endInvestmentDate,
            paymentMode,
        }
    );

export const GET_UPCOMING_REPAYMENTS = () =>
    UrlParamsReplace(`/investors/investments/upcoming`);

export const GET_INVESTOR_INVESTMENT = (investorId, investmentId) =>
    UrlParamsReplace(`/investors/:investorId/investments/:investmentId`, {
        investorId,
        investmentId,
    });

export const UPDATE_FREECHARGE_PAYMENT_REQUEST_URL = () =>
    UrlParamsReplace('/freecharge/paymentRequest');

export const PAYMENT_RETRY_URL = () =>
    UrlParamsReplace('/payment/retry');

export const DOWNLOAD_INVESTMENT_CASHFLOWS_URL = (investmentId) =>
    UrlParamsReplace('/investments/:investmentId/investment-cash-flows', {
        investmentId,
    });

export const DOWNLOAD_INVESTMENT_URL = (investorId) =>
    UrlParamsReplace('/investments/download?investorId=:investorId', {
        investorId,
    });

export const DOWNLOAD_UPCOMING_REPAYMENT_URL = (investorId) =>
    UrlParamsReplace(
        '/investments/upcoming-repayment/download?investorId=:investorId',
        {
            investorId,
        }
    );

export const GET_OFFLINE_INVESTMENT_PENDING_DOCUMENTS = (params) =>
    getUrlFromMultipleQueryParams('/investments/pending-documents', params);

export const ADD_OFFLINE_SIGNATURE_PENDING_DOCUMENTS = () =>
    UrlParamsReplace('/investor-opportunity-pending-documents');
